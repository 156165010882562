import React, {useState} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {ReactTypeformEmbed} from "react-typeform-embed";
import ContactPigeonForm, {
  CPEmail,
  CPSubmitButton,
  CPCheckbox,
} from "../components/contact-pigeon-form";
import NewsletterForm from "../components/newsletter-form";
const ContactUs: React.FC = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  return (
    <Layout>
      <SEO title="Contact us" description="Let’s talk!" />
      <h1 className="text-4xl font-bold pt-16 md:pt-24 lg:32 mb-12 md:mb-20 lg:mb-24">
        Nice to meet you!
      </h1>
      <div className="relative mb-26 min-h-64" style={{height: "30vw"}}>
        <ReactTypeformEmbed url="https://form.typeform.com/to/NtgCmJ7T" />
      </div>
      {/* <div className="p-8 flex flex-col md:flex-row items-center border-2 border-solid border-primary mb-12 md:mb-20 lg:mb-24">
        <div className="flex flex-col w-full md:w-5/12">
          <h2 className="text-primary text-4xl font-bold mb-4">
            Subscribe to newsletter
          </h2>

          <p className="w-full text-sm mb-6 md:mb-0 md:mr-8">
            Get a bi-monthly email with news, interesting reads, and useful tips
            about startups.
          </p>
        </div>
        <ContactPigeonForm className="md:mx-auto w-full md:w-3/5 flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start px">
          <div className="flex flex-col w-full">
            <CPEmail className="px-2 w-full mb-4" />
            <CPCheckbox
              isAgreed={isAgreed}
              setIsAgreed={setIsAgreed}
              className="block"
            ></CPCheckbox>
          </div>
          <CPSubmitButton
            isAgreed={isAgreed}
            className="h-11 bg-primary text-white flex justify-center items-center text-sm px-4 border-2 border-primary border-solid"
          ></CPSubmitButton>
        </ContactPigeonForm>
      </div> */}
      <NewsletterForm />
    </Layout>
  );
};

export default ContactUs;
